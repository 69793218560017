<div class="div col-12">
  <div class="div-2 col-12">
    <div class="div-3 col-12">
      <div class="column col-8">
        <div class="div-4">
          <div class="div-5">

            <img [src]="logoUrl"
              class="img-2" />
          </div>
          <div class="language-dropdown">
            <!-- <select (change)="switchLanguage($event)">
              <option value="en">
                <img src="assets/flags/us.svg" alt="English" width="20"> English
              </option>
              <option value="fr">
                <img src="assets/flags/ci.svg" alt="Ivory Coast" width="20"> Ivory Coast (Français)
              </option>
            </select> -->
          </div>
          <div class="div-6">{{placeHolders['WELCOME_TO']}} 
            '{{placeHolders['MY_UNIVERSAL_DEALS']}}'</div>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a34dbae2-821c-49c0-a993-3e2217bad47a?"
            class="img-3" />
          <div class="div-7">{{ placeHolders['CREATE_DEALS'] }}</div>
          <div class="div-8">
            {{placeHolders['PARA_BELOW_CREATE_DEALS_SEAMLESSLY_CREATE']}}
          </div>
          <div class="div-9">
            <div class="div-10"></div>
            <div class="div-11"></div>
            <div class="div-12"></div>
          </div>
        </div>
      </div>
      <div class="column-2 col-4">
        <div class="div-13 form-group">
          <form [formGroup]="logInForm" (keydown.enter)="logIn()">
            <div class="div-14">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/58865303-9ae8-4b3b-842b-ffd2f48ca140?"
                class="img-4" />
              <div class="div-15">{{placeHolders['NEW_MERCHANT']}}?</div>
              <!-- <nav> -->
                <!-- <button (click)="navigateToRegisterBots()" class=" registerNavigation registerButton">Botswana</button>
                <button (click)="navigateToRegisterZim()" class="mx-2 registerNavigation registerButton">Zimbabwe</button> -->
              <button (click)="navigateToRegister()" type="button"  class="registerButton">{{placeHolders['REGISTER_HERE']}}</button>
              <!-- </nav> -->
            </div>
            <div class="div-17">
              <div class="div-18">{{placeHolders['LOGIN']}}</div>
              <!-- Username control -->
              <!-- <div class="div-19 asterix">Email</div>
              <div>
                <input class="div-20 col-12" type="text" placeholder="Enter Email" autocomplete="email_id"
                  formControlName="email_id"
                  [ngClass]="{ 'is-invalid': f['email_id'].errors && (f['email_id'].dirty || f['email_id'].touched ) }"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
               
                <div class="invalid-feedback"
                  *ngIf="f['email_id'].errors && (f['email_id'].dirty || f['email_id'].touched )">
                  <div *ngIf="f['email_id'].errors['required']">Email is required</div>
                  <div *ngIf="f['email_id'].errors['pattern']">Invalid Email!!</div>
                </div>
              </div> -->

              <div class="col-12 form-group mt-3">
                <label for="emaillogin"  class="asterix">{{placeHolders['EMAIL']}}</label>
                <input class="form-control" id="emaillogin" type="text" placeholder="{{placeHolders['ENTER_EMAIL']}}" autocomplete="email_id"
                  formControlName="email_id"
                  [ngClass]="{ 'is-invalid': f['email_id'].errors && (f['email_id'].dirty || f['email_id'].touched ) }"
                   required />
                <!-- Validation message container -->
                <div class="invalid-feedback"
                  *ngIf="f['email_id'].errors && (f['email_id'].dirty || f['email_id'].touched )">
                  <div *ngIf="f['email_id'].errors['required']">{{placeHolders['MSG_EMAIL_REQUIRED']}}</div>
                  <div *ngIf="f['email_id'].errors['pattern']">{{placeHolders['INVALID']}}!!</div>
                </div>
              </div>

              <!-- Password control -->

              <div class="col-12 form-group mt-3">
                <label for="passowordlogin" class="asterix">{{placeHolders['PASSWORD']}}</label>
                <input placeholder="{{placeHolders['ENTER_PASSWORD']}}" id="passowordlogin" class="form-control" [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  [ngClass]="{ 'is-invalid': f['password'].errors && (f['password'].dirty || f['password'].touched ) }"
                  required />
                <mat-icon class="visibility" matSuffix (click)="togglePasswordVisibility()">
                  {{show ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <!-- Validation message container -->
                <div class="invalid-feedback"
                  *ngIf="f['password'].errors && (f['password'].dirty || f['password'].touched)">
                  <div *ngIf="f['password'].errors['required']">{{placeHolders['MSG_PASSWORD_REQUIRED']}}</div>
                </div>
              </div>

              <!-- <div class="div-21 asterix">Password</div>
              <div class="input-container">
                <input placeholder="Enter Password" class="div-22 col-12" [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  [ngClass]="{ 'is-invalid': f['password'].errors && (f['password'].dirty || f['password'].touched ) }"
                  required />
                <mat-icon class="pt-1 ml-30" matSuffix (click)="togglePasswordVisibility()">
                  {{show ? 'visibility_off' : 'visibility'}}
                </mat-icon>
              
                <div class="invalid-feedback"
                  *ngIf="f['password'].errors && (f['password'].dirty || f['password'].touched)">
                  <div *ngIf="f['password'].errors['required']">Password is required</div>
                </div>
              </div> -->


              <a class="div-23 " routerLink="/forgetPassword">{{placeHolders['FORGOT_PASSWORD']}}</a>

              <nav>
                <a class="button col-12" style="text-align: center;" routerLinkActive="active"
                  ariaCurrentWhenActive="page">
                  <button class="div-24"  (click)="logIn()">{{placeHolders['LOGIN']}}</button>
                </a>
              </nav>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader fgsColor="#93d847"  [logoUrl]="faviconLogo" fgsType="rotating-plane" pbColor="#93d847" text="{{placeHolders['LBL_LODER_SETTNG_UP_THINGS']}}"></ngx-ui-loader>
