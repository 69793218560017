import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-createflashdeal',
  templateUrl: './createflashdeal.component.html',
  styleUrl: './createflashdeal.component.scss'
})
export class CreateflashdealComponent implements ICellRendererAngularComp{
  params: any;
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;

  constructor() {
    this.placeHolders = this.placeholders['dashboard_page']
  }

  refresh(): boolean {
    return true;
  }

  agInit(params: any) {
    this.params = params;
  }

  view() {
    return this.params.clicked(this.params.data);
  }
}
