
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RetargetingService } from 'src/app/services/retargeting.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

interface TableRow {
  dealId: number;
  dealTitle: string;
  validTo: string;
  status: string;
  currentHighProspects: number;
  messagesSent: number;
  currentPendingProspects: number;
  totalSpent: number;
  originalData: any;  // This will hold the entire original object
}

// const DEAL_DATA: Deal[] = [
//   {dealId: 'CPGN4684', dealTitle: 'Armani New Bike..', validTo: '11/04/2023', status: 'Active', currentHighProspects: 5000, messagesSent: 1000, currentPendingProspects: 4000, totalSpent: 1000},
//   {dealId: 'CPGN4685', dealTitle: 'Deal_00NewYear2024_lakme--', validTo: '11/04/2023', status: 'Active', currentHighProspects: 3000, messagesSent: 2000, currentPendingProspects: 1000, totalSpent: 2000},
//   {dealId: 'CPGN4686', dealTitle: 'Deal_00NewYear2024_lakme', validTo: '11/04/2023', status: 'Active', currentHighProspects: 4500, messagesSent: 3000, currentPendingProspects: 1500, totalSpent: 3000},
//   {dealId: 'CPGN4687', dealTitle: 'Deal_00NewYear2024_lakme', validTo: '11/04/2023', status: 'Active', currentHighProspects: 5000, messagesSent: 2800, currentPendingProspects: 2200, totalSpent: 2800},
//   {dealId: 'CPGN4688', dealTitle: 'Deal_00NewYear2024_lakme', validTo: '11/04/2023', status: 'Active', currentHighProspects: 6000, messagesSent: 4500, currentPendingProspects: 1500, totalSpent: 4500},
// ];

@Component({
  selector: 'app-messageretargeting',
  templateUrl: './messageretargeting.component.html',
  styleUrl: './messageretargeting.component.scss'
})
export class MessageretargetingComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  reportPlaceHolders:any;
  userData: any;
  activePage: number = 1;
  displayedColumns: string[] = ['dealId', 'dealTitle', 'validTo', 'status', 'currentHighProspects', 'messagesSent', 'currentPendingProspects', 'totalSpent'];
  dataSource = new MatTableDataSource<TableRow>();
  totalCount: number = 0;
  currentPage: any;
  pageSize: number = 10;
  filterValue =''
  countryData:any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private router: Router,private route: ActivatedRoute, private retargetingService: RetargetingService, private clientStorage: ClientSideStorageService) {
    this.placeHolders = this.placeholders['Messaging_Page']
    this.reportPlaceHolders = this.placeholders['Reports_page']
    this.userData = JSON.parse(this.clientStorage.get("marketplaceData"));
    this.route.data.subscribe((data: any) => {
      this.countryData = this.placeholders['locale'] === 'fr'? data.resolvedData.data.countries :data.resolvedData.data
    });
  }

  ngOnInit() {
    this.loadTableData('A');
    this.dataSource.paginator = this.paginator;
  }

  countryName:any;
  onRowClicked(row: TableRow) {
    console.log('Row clicked: ', row.originalData);
    let countryId = parseInt(row.originalData.deal.country);
    let CountryValues = this.countryData.filter((country: any) => country.country_id === countryId);//this.countryData.filter((option:any) => option.country_id === this.userData.country);
    this.countryName = CountryValues[0].country_name
    const enhancedData = {
      ...row.originalData,
      deal: {
        ...row.originalData.deal,
        countryName: this.countryName
      }
    };
    this.retargetingService.setData(enhancedData);
    this.router.navigate(["/messaging/retargeting/" + row.dealId]);
  }

  recordTotalCount: any;
  tableData: any;
 
  loadTableData(param:any) {
    let data = {
      keywords: [],
      pagination: this.activePage,
      filterStatus: param
    }
    this.retargetingService.getFavOrClaimedDeals(data, this.userData.marketplace_id)
      .pipe(
        map((res: any) => {
          this.recordTotalCount = res?.body?.data?.TOTAL_DEALS;
          return res?.body?.data?.FAVORITED_DEALS.map((item: any) => ({
            dealId: item.deal.product_id,
            dealTitle: item.deal.product_name,
            validTo: item.deal.available_to,
            status: item.deal.status,
            currentHighProspects: item.details.totalUniqueProspects,
            messagesSent: item.details.totalCustomerOfferMade,
            currentPendingProspects: item.details.totalPendingProspects,
            totalSpent: item.details.totalSpend,
            originalData: item  // Store the entire original item
          }));
        })
      )
      .subscribe((transformedData: TableRow[]) => {
        this.tableData = transformedData;
        this.dataSource = this.tableData;
        this.totalCount = this.recordTotalCount;
        this.currentPage = this.activePage;
        // You can now use this.tableData as the data source for your table
      });
  }

  onPageChange(page: number): void {
    this.activePage = page;
    this.loadTableData(this.filterValue);
  }

  getStatusClass(status: string): string {
    switch(status) {
      case 'A': return 'active-chip';
      case 'P': return 'pending-chip';
      case 'E': return 'expired-chip';
      case 'I': return 'inactive-chip';
      case 'D': return 'draft-chip';
      case 'U': return 'upcoming-chip';
      case 'S': return 'paused-chip';
      case 'R': return 'rejected-chip';
      case 'N': return 'new-chip';
      case 'C': return 'completed-chip';
      default: return '';
    }
  }
  
  getStatusText(status: string): string {
    switch(status) {
      case 'A': return this.reportPlaceHolders['LBL_ACTIVE'];
      case 'P': return this.reportPlaceHolders['LBL_PENDING'];
      case 'E': return this.reportPlaceHolders['LBL_EXPIRED'];
      case 'I': return this.reportPlaceHolders['LBL_INACTIVE'];
      case 'D': return this.reportPlaceHolders['LBL_DRAFT'];
      case 'U': return this.reportPlaceHolders['LBL_UPCOMING'];
      case 'S': return this.reportPlaceHolders['LBL_PAUSED'];
      case 'R': return this.reportPlaceHolders['LBL_REJECTED'];
      case 'N': return this.reportPlaceHolders['LBL_NEW'];
      case 'C': return this.reportPlaceHolders['LBL_COMPLETED'];
      default: return 'Unknown';
    }
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLSelectElement).value;
    // this.totalCount = 0
    this.activePage = 1
    this.currentPage = 1
    this.loadTableData(this.filterValue)
  }



}